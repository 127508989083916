import '../../../../src/components/protons/icon/Icon';
import '../anchor/Anchor';
import type { IconProps } from '../../../../src/components/protons/icon/Icon';
import './Label.pcss';

export enum LabelSize {
    DEFAULT = 'default',
    SMALL = 'small',
    REGULAR = 'regular'
}

export type LabelProps = {
    label?: string;
    htmlFor?: string;
    size?: LabelSize;
    icon?: IconProps;
    partial?: 'default' | 'payment-icon' | 'info-tip';
    tooltipId?: string;
};

export default class Label extends HTMLLabelElement {
    constructor(props?: LabelProps) {
        super();

        if (props) {
            this.htmlFor = props.htmlFor || this.htmlFor;
        }

        this.#update();
    }

    #update(): void {
        this.classList.add('nh-label-new');
    }
}

if (window.customElements.get('nh-label')) {
    window.customElements.define('nh-label', Label, { extends: 'label' });
}
